<template>
  <div>
    <Modal class="pop-complete" footer-hide v-model="showLogin">
      <div class="pop-login-main">
        <div class="top">
          <div class="logo">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="title">Welcome to Connexima</div>
        </div>
        <div class="desc"></div>
        <div class="email content-input">
          <div class="title">Email</div>
          <div class="input">
            <input
              placeholder=""
              type="text"
              v-model="loginForm.email"
              readonly="readonly"
              style="background: #f1f1f5"
            />
          </div>
        </div>
        <div class="email content-input content-input-flex">
          <div class="item">
            <div class="title">First Name<span class="red-point">*</span></div>
            <div class="input">
              <input
                placeholder=""
                type="text"
                v-model="loginForm.first_name"
              />
            </div>
          </div>
          <div class="item">
            <div class="title">Last Name<span class="red-point">*</span></div>
            <div class="input">
              <input placeholder="" type="text" v-model="loginForm.last_name" />
            </div>
          </div>
        </div>
        <div class="email content-input content-input-flex">
          <div class="item">
            <div class="title">
              Company Name<span class="red-point">*</span>
            </div>
            <div class="input">
              <input
                placeholder=""
                type="text"
                v-model="loginForm.company_name"
              />
            </div>
          </div>
          <div class="item">
            <div class="title">Country<span class="red-point">*</span></div>
            <div class="input">
              <select v-model="loginForm.company_country_address">
                <option
                  v-for="item in countryList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</option
                >
              </select>
            </div>
          </div>
        </div>
        <div class="email content-input">
          <div class="title">Website</div>
          <div class="input input-website">
            <select v-model="loginForm.company_http_str">
              <option value="https://">Https://</option>
              <option value="http://">Http://</option>
              >
            </select>
            <input
              placeholder=""
              type="text"
              v-model="loginForm.websiteContent"
            />
          </div>
        </div>
        <div
          :class="[
            'login-submit',
            'search-button',
            { 'login-submit-waiting': loginSubmitWaiting }
          ]"
          @click="register"
        >
          {{ signInText }}
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      loginForm: {
        role: "exhibitor",
        email: "",
        company_name: "",
        company_country_address: 1,
        first_name: "",
        last_name: "",
        website: "",
        company_http_str: "https://",
        websiteContent: "",
        uniqStr: ""
      },
      showLogin: false,
      signInText: "Confirm",
      loginSubmitWaiting: false,
      uStr: "",
      countryList: [
        { id: 1, name: "United States" },
        { id: 2, name: "Canada" },
        { id: 45, name: "China" }
      ]
    };
  },
  mounted() {
    let social = this.$route.query.social;
    console.log(social);
    if (typeof social != "undefined") {
      this.loginForm.uniqStr = social;
    }
    this.getCountry();
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$store
        .dispatch("user/getInfoViaUniqStr", { token: this.loginForm.uniqStr })
        .then(res => {
          if (res.email !== undefined) {
            this.loginForm.email = res.email;
            let nameArr = res.name.split(" ");
            this.loginForm.first_name = nameArr[0];
            this.loginForm.last_name = nameArr[1];
          }
        });
    },
    login() {
      this.$emit("login");
    },
    changeRole(role) {
      this.loginForm.role = role;
    },
    getCountry() {
      this.$store.dispatch("user/getRegisterCountry").then(res => {
        this.countryList = res;
      });
    },
    register() {
      const {
        loginForm: { email, company_name, country, first_name, last_name }
      } = this;

      if (email == "") {
        this.$Message.error("Please enter your email!");
        return;
      }
      if (first_name == "" || last_name == "") {
        this.$Message.error("Please enter your first name and last name!");
        return;
      }
      if (company_name == "" || country == "") {
        this.$Message.error("Please enter company name and country!");
        return;
      }
      this.loading = true;
      this.loginSubmitWaiting = true;
      this.signInText = "Confirming";
      if (this.loginForm.websiteContent !== "") {
        this.loginForm.website =
          this.loginForm.company_http_str + this.loginForm.websiteContent;
      }
      this.$store
        .dispatch("user/authRegister", this.loginForm)
        .then(res => {
          console.log(res);
          this.loginSubmitWaiting = false;
          this.showLogin = false;
          this.loginForm = {
            role: "exhibitor",
            email: "",
            company_name: "",
            company_country_address: 1,
            first_name: "",
            last_name: "",
            website: "",
            websiteContent: "",
            company_http_str: "https://",
            uniqStr: ""
          };
          this.$router.push("/");
        })
        .catch(({ message }) => {
          this.signInText = "Create Account";
          this.loginSubmitWaiting = false;
          this.$Message.error(message);
        })
        .finally(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
.pop-login-main {
  height: 570px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    .logo {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      img {
        width: 170px;
      }
    }
    .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 37px;
      display: flex;
      align-items: center;
      text-align: center;

      /* Black */

      color: #222222;
      margin-top: 5px;
    }
    .close {
    }
  }
  .desc {
    /*margin: 10px auto 18px auto;*/
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
  }
  .type {
    .type-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height, or 140% */

      display: flex;
      align-items: center;

      color: #000000;
      margin-bottom: 10px;
    }
    .types {
      display: flex;
      justify-content: space-between;
      width: 470px;
      .active-buyer {
        background: #f99d20;
        .buyer-name {
          color: #f2f2f2 !important;
        }
      }
      .buyer {
        display: flex;
        width: 230px;
        height: 50px;
        border: 1px solid #f57f45;
        box-sizing: border-box;
        border-radius: 8px;
        .buyer-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 15px;
        }
        .buyer-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;

          /* Black */

          color: #222222;

          /* Inside Auto Layout */

          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0px 16px;
        }
      }

      .active-seller {
        background: #f99d20;
        .seller-name {
          color: #f2f2f2 !important;
        }
      }
      .seller {
        display: flex;
        width: 230px;
        height: 50px;
        border: 1px solid #f57f45;
        box-sizing: border-box;
        border-radius: 8px;
        .seller-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 15px;
        }
        .seller-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;

          /* Black */

          color: #222222;

          /* Inside Auto Layout */

          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0px 16px;
        }
      }
    }
  }
  .content-input {
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      /* identical to box height, or 140% */

      display: flex;
      align-items: center;

      color: #000000;
      margin: 15px auto 10px auto;
      .red-point {
        color: red;
        position: relative;
        top: 4px;
        left: 3px;
        font-size: 18px;
      }
    }
    .input {
      input {
        width: 470px;
        height: 40px;
        left: 527px;
        top: 702px;

        /* Gray 6 */

        /*background: #f2f2f2;*/
        border-radius: 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        display: flex;
        align-items: center;

        /* Gray 3 */

        color: #000;
        border: none;
        outline: none;
        padding-left: 10px;
        border: 1px solid #cbc8c8;
      }
      select {
        width: 225px;
        height: 40px;
        left: 527px;
        top: 702px;

        /* Gray 6 */

        /*background: #f2f2f2;*/
        border-radius: 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        display: flex;
        align-items: center;

        /* Gray 3 */

        color: #000;
        border: none;
        outline: none;
        padding-left: 10px;
        border: 1px solid #cbc8c8;
      }
      input:focus {
        background: #fff;
        border: 1px solid #f57f45;
      }
    }
  }
  .content-input-flex {
    display: flex;
    justify-content: space-between;
    width: 470px;
    .input input {
      width: 225px;
    }
  }
  .forgot {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: right;
    width: 470px !important;

    /* Gray 1 */

    color: #333333;
    margin: 16px auto;
  }
  .login-submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    width: 469px;
    height: 45px;
    background: #f57f45;
    /* Shadow */

    box-shadow: 4px 4px 20px rgba(34, 34, 34, 0.1);
    border-radius: 8px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    /* padding-top: 10px; */
    cursor: pointer;
    border: none;
    margin-top: 30px;
  }
  .login-submit-waiting {
    opacity: 0.7;
  }
  .register {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    margin-top: 30px;
    .register-here {
      color: #222222;
      font-weight: 600;
      margin-left: 5px;
    }
    span {
      font-weight: 600;
      font-size: 14px;
      color: #222;
      cursor: pointer;
      position: relative;
      bottom: 1px;
      left: 3px;
    }
  }
}
.input-website {
  display: flex;
  input {
    width: 363px !important;
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  select {
    width: 105px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
.sign-in-with {
  position: relative;
  top: 15px;
  .word {
    display: flex;
    justify-content: space-between;
    width: 470px;
    .left-inline {
      height: 1px;
      background: #ddd;
      width: 160px;
      position: relative;
      top: 10px;
    }
  }
  .icons {
    display: flex;
    width: 185px;
    justify-content: space-between;
    margin: 15px auto 0 auto;
    .icon {
      cursor: pointer;
      img {
        width: 27px;
      }
    }
  }
}
</style>

<style lang="less">
.pop-complete {
  .ivu-modal {
    width: 570px !important;
  }
}
</style>
